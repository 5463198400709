import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import withTrans from "root/i18n/withTrans";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/ProductHero";
import ProductValuePropositions from "root/sections/ProductValuePropositions";
import ProductTestimonials from "root/sections/Testimonials";
import ProductInstitutions from "root/sections/ProductInstitutions";
import ProductKnowMore from "root/sections/ProductKnowMore";
import Footer from "root/components/Footer";

/* eslint-disable id-length */
const ProductHomePage = ({ t }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "projects1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications1: file(relativePath: { eq: "specifications-mobile.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      specifications2: file(relativePath: { eq: "home-values1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      testimonial: file(relativePath: { eq: "testimonial.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      knowMore: file(relativePath: { eq: "institutions.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div>
      <SEO title={t("pages.productHome.label")} description="" keywords="" />
      <Layout>
        <Navbar
          color="blue"
          currentPage="product-home"
          translations={t("pages")}
          buttonColor="purple"
        />
        <Hero
          image={data.hero}
          color="purple"
          translations={t("productHome.hero")}
        />
        <ProductValuePropositions
          phoneImage={data.specifications1}
          image={data.specifications2}
          translations={t("productHome.valuePropositions")}
          color="purple"
        />
        <ProductKnowMore
          translations={t("productHome.knowMore")}
          image={data.knowMore}
          color="purple"
        />
        <ProductTestimonials
          color="purple"
          translations={t("productHome.testimonials")}
          videoUrl="https://www.youtube.com/embed/fLB4i9SpJN8"
          image={data}
        />
        <ProductInstitutions
          translations={t("home.institutions")}
          color="purple"
        />
        <Footer translations={t("pages")} />
      </Layout>
    </div>
  );
};

ProductHomePage.propTypes = {
  t: PropTypes.func.isRequired,
};
/* eslint-enable id-length */

export default withTrans(ProductHomePage);
